<template>
  <div class="observe">
    <Head :headdata="headdata"></Head>
    <div class="observeMain common-main">
      <h2 class="title_zh">
        观点
        <div class="fenxBox">
          <img class="fenx" src="../assets/images/icon_fenx.png" alt />
          <p>分享</p>
          <share/>
        </div>
      </h2>
      <h2 class="title_en">Opinions</h2>
      <!-- <p class="title_text">
        bicc湾创团队汇聚来自国内外从事文旅产业多年的专业人才。<br>
        引领人是具备权威性、实践性的中国文化旅游及主题公园行业发展变迁的亲历者和创想者，<br>
        带领了一支拥有国际视野、家国情怀及本土智慧的多学科团队。<br>
      </p> -->
      <div class="search">
        <input type="text" v-model="valueText" placeholder="按分类检索" />
        <img @click="setSearch" class="searchImg" src="../assets/images/icon_search.png" alt />
      </div>
      <div class="navBox" v-if="observeYears.length > 0">
        <ul class="clearfloat">
          <li v-for="(item,index) in observeYears" :key="index">
            <router-link class="bar-item" :to="{ name: 'observe', params: { year: item }}">{{item}}</router-link>
          </li>
        </ul>
      </div>
      <div class="projectListsBox">
        <Projectlists ajaxUrl="/wc/dongs"></Projectlists>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/header.vue";
import Projectlists from "@/components/projectlists.vue";
import share from "@/components/share.vue";
export default {
  data() {
    return {
      headdata: this.$t("m.projectHeaddatb"),
      listsData: this.$t("m.sidebarList"),
      valueText: "",
      observeYears: [],
    };
  },
  created(){
    this.getdata();
  },
  components: {
    Head,
    Projectlists,
    share
  },
  methods: {
    getdata() {
      this.axios.get(this.GLOBAL.serverSrc + "/wc/dongsYears").then((res) => {
        this.observeYears = res.data.data;
      });
    },
    updated() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    setSearch() {
      if (!this.valueText) {
        return;
      }
      this.$router.push({
        name: "search",
        params: { ajaxUrl: "/wc/dongs", value: this.valueText },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.observe {
  height: 100%;
  width: 100%;
  .observeMain {
    width: 100%;
    padding: 78px 200px 0 200px;
    .navBox {
      width: 100%;
      height: 80px;
      border-bottom: 1px solid #ccc;
      ul {
        li {
          width: 20%;
          float: left;
          text-align: left;
          font-size: 20px;
          height: 80px;
          line-height: 80px;
          a {
            color: #808080;
            font-weight: bold;
          }
          .router-link-exact-active,
          .router-link-active {
            color: #d38022;
          }
        }
      }
    }
    .title_zh {
      color: #000;
      font-size: 34px;
      cursor: pointer;
     
      .fenxBox {
        float: right;
        cursor: pointer;
        .fenx {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 12px;
          color: #808080;
        }
      }
    }
    .title_en {
      cursor: pointer;
      // margin-bottom: 60px;
     
    }
    .title_text {
      font-size: 18px;
      // margin-bottom: 60px;
      color: #000;
    }
    .search {
      margin-top: 150px;
      width: 100%;
      border-bottom: 1px solid #ccc;
      input {
        width: 90%;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        padding-left: 2px;
      }
      .searchImg {
        width: 40px;
        height: 40px;
        padding: 10px;
        float: left;
      }
    }
    .projectListsBox {
      width: 100%;
    }
  }
}
@media (max-width: 768px) { 
  .observe .observeMain .navBox {height: 60px;}
  .observe .observeMain .navBox ul li {
    height: 60px;
    line-height: 60px;
  }
  .title_en {
    font-size: 24px;  
  }
   .bar-item {
       font-size: 14px;
     }
  }
  
</style>